import styled from "styled-components";

export const QueryBuilderForm = styled.form`
  padding: 8px;
  border-bottom: 2px grey solid;
  margin-bottom: 8px;
  width: 100%;
`;

export const QueryShortcutContainer = styled.div`
  flex: "row wrap";
  margin-top: "8px";
  height: 200px;
  overflow: auto;
`;
