import React from "react";
import { registerUIComponent } from "../engine";
import {
  defineQuery,
  EntityID,
  getComponentValue,
  getComponentValueStrict,
  Has,
  hasComponent,
  HasValue,
  runQuery,
  UpdateType,
} from "@latticexyz/recs";
import { map, merge } from "rxjs";

export function registerFactoryView() {
  registerUIComponent(
    "Factory",
    {
      rowStart: 11,
      rowEnd: 13,
      colStart: 7,
      colEnd: 10,
    },
    (layers) => {
      const {
        local: {
          components: { Selection, Selected, Name },
          singletonEntity,
        },
        network: {
          world,
          components: { OwnedBy, Inventory, ItemType, Factory },
        },
      } = layers;

      return merge(defineQuery([Has(Selected), Has(Factory)]).update$).pipe(
        map(({ entity, type }) => {
          let factory = getComponentValue(Factory, entity);
          if(UpdateType.Exit === type) factory = undefined;

          return { Name, world, factory };
        })
      );
    },
    ({ Name, factory, world }) => {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            border: "1px grey solid",
            width: "100%",
          }}
        >
          {factory && (
            <div>
              <h1>Build Options</h1>
              <ol>
                {factory.prototypeIds.map((prototypeId, i) => {
                  const name = getComponentValue(Name, world.entityToIndex.get(prototypeId as EntityID)!);

                  return <li key={`${prototypeId}-${i}`}>{name?.value}: {factory.costs[i]} Gold</li>;
                })}
              </ol>
            </div>
          )}
        </div>
      );
    }
  );
}
